@use 'const';
@use 'theme';
@use 'mixins';
@use 'designSystem';

.button {
  display: flex;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  border-style: solid;
  border-width: const.$buttonInputBorderWidth;
  border-radius: const.$buttonRadius;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @include theme.apply(background-color, action-default);
  @include theme.apply(border-color, action-default);
  @include theme.apply(color, action-on-action);
  @include mixins.svgColor(action-on-action);
  @include designSystem.textStyleHeadingMedium24;

  height: const.$buttonheight;
  transition: background-color 0.2s ease;

  &[type='button'] {
    appearance: none;
  }

  &:hover {
    @include theme.apply(background-color, action-darken);
    @include theme.apply(border-color, action-darken);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }

  &:active {
    @include theme.apply(background-color, action-darkest);
    @include theme.apply(border-color, action-darkest);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }

  &:focus {
    @include theme.apply(background-color, action-darken);
    @include theme.apply(border-color, action-lighten);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);
  }

  &.disabled {
    @include theme.apply(background-color, action-disabled);
    @include theme.apply(border-color, action-disabled);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);

    cursor: not-allowed;
  }

  &.medium {
    @include designSystem.textStyleHeadingSmall18;

    padding-left: const.$margin * 1.33;
    padding-right: const.$margin * 1.33;
    height: const.$buttonheightMedium;
  }

  &.small {
    @include designSystem.textStyleHeadingSmall16;

    padding-left: const.$margin;
    padding-right: const.$margin;
    height: const.$buttonSmallHeight;
    width: unset;
  }

  &.action {
    @include theme.apply(background-color, action-default);
    @include theme.apply(border-color, action-default);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);

    &:hover {
      @include theme.apply(background-color, action-darken);
      @include theme.apply(border-color, action-darken);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }

    &:active {
      @include theme.apply(background-color, action-darkest);
      @include theme.apply(border-color, action-darkest);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }

    &:focus {
      @include theme.apply(background-color, action-darken);
      @include theme.apply(border-color, action-lighten);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }

    &.disabled {
      @include theme.apply(background-color, action-disabled);
      @include theme.apply(border-color, action-disabled);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }
  }

  &.blue-transparent,
  &.action-transparent {
    @include theme.apply(border-color, action-default);
    @include theme.apply(color, action-default);
    @include mixins.svgColor(action-default);

    background-color: transparent;

    &:hover {
      @include theme.apply(background-color, action-lightest);
      @include theme.apply(border-color, action-default);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &:active {
      @include theme.apply(border-color, action-darkest);
      @include theme.apply(color, action-darkest);
      @include mixins.svgColor(action-darkest);

      background-color: transparent;
    }

    &:focus {
      @include theme.apply(border-color, action-default);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, action-disabled);
      @include theme.apply(color, action-disabled);
      @include mixins.svgColor(action-disabled);

      background-color: transparent;
    }
  }

  &.action-light {
    @include theme.apply(background-color, action-light-default);
    @include theme.apply(border-color, action-light-default);
    @include theme.apply(color, action-default);
    @include mixins.svgColor(action-default);

    &:hover {
      @include theme.apply(background-color, action-light-darken);
      @include theme.apply(border-color, action-light-darken);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &:active {
      @include theme.apply(background-color, action-light-default);
      @include theme.apply(border-color, action-light-default);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &:focus {
      @include theme.apply(background-color, action-light-default);
      @include theme.apply(border-color, action-light-darken);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &.disabled {
      @include theme.apply(background-color, action-light-disabled);
      @include theme.apply(border-color, action-light-disabled);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }
  }

  &.action-light-transparent {
    @include theme.apply(border-color, action-light-default);
    @include theme.apply(color, action-default);
    @include mixins.svgColor(action-default);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, action-light-darken);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);

      background-color: transparent;
    }

    &:active {
      @include theme.apply(border-color, action-light-darkest);
      @include theme.apply(color, action-light-darkest);
      @include mixins.svgColor(action-light-darkest);

      background-color: transparent;
    }

    &:focus {
      @include theme.apply(border-color, action-light-darken);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, action-light-disabled);
      @include theme.apply(color, action-light-disabled);
      @include mixins.svgColor(action-light-disabled);

      background-color: transparent;
    }
  }

  &.foreground {
    @include theme.apply(background-color, foreground-default);
    @include theme.apply(border-color, foreground-default);
    @include theme.apply(color, foreground-on-foreground);
    @include mixins.svgColor(foreground-on-foreground);

    &:hover {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, foreground-darken);
      @include theme.apply(color, foreground-on-foreground);
      @include mixins.svgColor(foreground-on-foreground);
    }

    &:active {
      @include theme.apply(background-color, foreground-darkest);
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, foreground-on-foreground);
      @include mixins.svgColor(foreground-on-foreground);
    }

    &:focus {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, foreground-lighten);
      @include theme.apply(color, foreground-on-foreground);
      @include mixins.svgColor(foreground-on-foreground);
    }

    &.disabled {
      @include theme.apply(background-color, foreground-disabled);
      @include theme.apply(border-color, foreground-disabled);
      @include theme.apply(color, foreground-on-foreground);
      @include mixins.svgColor(foreground-on-foreground);
    }
  }

  &.foreground-transparent {
    @include theme.apply(border-color, foreground-default);
    @include theme.apply(color, foreground-default);
    @include mixins.svgColor(foreground-default);

    background-color: transparent;

    &:hover {
      @include theme.apply(background-color, foreground-lightest);
      @include theme.apply(border-color, foreground-default);
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, foreground-darkest);
      @include mixins.svgColor(foreground-darkest);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, foreground-disabled);
      @include theme.apply(color, foreground-disabled);
      @include mixins.svgColor(foreground-disabled);

      background-color: transparent;
    }
  }

  &.on-action {
    @include theme.apply(background-color, action-on-action);
    @include theme.apply(border-color, action-on-action);
    @include theme.apply(color, action-default);
    @include mixins.svgColor(action-default);

    &:hover {
      @include theme.apply(background-color, action-lightest);
      @include theme.apply(border-color, action-lightest);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &:active {
      @include theme.apply(background-color, action-light-default);
      @include theme.apply(border-color, action-light-default);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &:focus {
      @include theme.apply(background-color, action-on-action);
      @include theme.apply(border-color, action-lightest);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }

    &.disabled {
      @include theme.apply(background-color, action-on-action-disabled);
      @include theme.apply(border-color, action-on-action-disabled);
      @include theme.apply(color, action-default);
      @include mixins.svgColor(action-default);
    }
  }

  &.on-action-transparent {
    @include theme.apply(border-color, action-on-action);
    @include theme.apply(color, action-on-action);
    @include mixins.svgColor(action-on-action);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, action-lightest);
      @include theme.apply(color, action-lightest);
      @include mixins.svgColor(action-lightest);

      background-color: transparent;
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, action-light-default);
      @include theme.apply(color, action-light-default);
      @include mixins.svgColor(action-light-default);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, action-on-action-disabled);
      @include theme.apply(color, action-on-action-disabled);
      @include mixins.svgColor(action-on-action-disabled);

      background-color: transparent;
    }
  }

  &.on-accent-1 {
    @include theme.apply(background-color, accent-1-on-accent-1);
    @include theme.apply(border-color, accent-1-on-accent-1);
    @include theme.apply(color, accent-1-default);
    @include mixins.svgColor(accent-1-default);

    &:hover {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, foreground-darken);
      @include theme.apply(color, accent-1-default);
      @include mixins.svgColor(accent-1-default);
    }

    &:active {
      @include theme.apply(background-color, foreground-darkest);
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, accent-1-default);
      @include mixins.svgColor(accent-1-default);
    }

    &:focus {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, accent-1-darken);
      @include theme.apply(color, accent-1-default);
      @include mixins.svgColor(accent-1-default);
    }

    &.disabled {
      @include theme.apply(background-color, accent-1-on-accent-1-disabled);
      @include theme.apply(border-color, accent-1-on-accent-1-disabled);
      @include theme.apply(color, accent-1-default);
      @include mixins.svgColor(accent-1-default);
    }
  }

  &.on-accent-1-transparent {
    @include theme.apply(border-color, accent-1-on-accent-1);
    @include theme.apply(color, accent-1-on-accent-1);
    @include mixins.svgColor(accent-1-on-accent-1);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, foreground-darken);
      @include theme.apply(color, foreground-darken);
      @include mixins.svgColor(foreground-darken);

      background-color: transparent;
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, foreground-darkest);
      @include mixins.svgColor(foreground-darkest);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, accent-1-on-accent-1-disabled);
      @include theme.apply(color, accent-1-on-accent-1-disabled);
      @include mixins.svgColor(accent-1-on-accent-1-disabled);

      background-color: transparent;
    }
  }

  &.on-accent-2 {
    @include theme.apply(background-color, accent-2-on-accent-2);
    @include theme.apply(border-color, accent-2-on-accent-2);
    @include theme.apply(color, accent-2-default);
    @include mixins.svgColor(accent-2-default);

    &:hover {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, foreground-darken);
      @include theme.apply(color, accent-2-default);
      @include mixins.svgColor(accent-2-default);
    }

    &:active {
      @include theme.apply(background-color, foreground-darkest);
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, accent-2-default);
      @include mixins.svgColor(accent-2-default);
    }

    &:focus {
      @include theme.apply(background-color, foreground-darken);
      @include theme.apply(border-color, accent-2-default);
      @include theme.apply(color, accent-2-default);
      @include mixins.svgColor(accent-2-default);
    }

    &.disabled {
      @include theme.apply(background-color, accent-2-on-accent-2-disabled);
      @include theme.apply(border-color, accent-2-on-accent-2-disabled);
      @include theme.apply(color, accent-2-default);
      @include mixins.svgColor(accent-2-default);
    }
  }

  &.on-accent-2-transparent {
    @include theme.apply(border-color, foreground-default);
    @include theme.apply(color, foreground-default);
    @include mixins.svgColor(foreground-default);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, foreground-darken);
      @include theme.apply(color, foreground-darken);
      @include mixins.svgColor(foreground-darken);

      background-color: transparent;
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, foreground-darkest);
      @include theme.apply(color, foreground-darkest);
      @include mixins.svgColor(foreground-darkest);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, accent-2-lighten);
      @include theme.apply(color, accent-2-lighten);
      @include mixins.svgColor(accent-2-lighten);

      background-color: transparent;
    }
  }

  &.brand {
    @include theme.apply(background-color, brand-default);
    @include theme.apply(border-color, brand-default);
    @include theme.apply(color, brand-on-brand);
    @include mixins.svgColor(brand-on-brand);

    &:hover {
      @include theme.apply(background-color, brand-darken);
      @include theme.apply(border-color, brand-darken);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }

    &:active {
      @include theme.apply(background-color, brand-darkest);
      @include theme.apply(border-color, brand-darkest);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }

    &:focus {
      @include theme.apply(background-color, brand-default);
      @include theme.apply(border-color, brand-lighten);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-default);
    }

    &.disabled {
      @include theme.apply(background-color, brand-disabled);
      @include theme.apply(border-color, brand-disabled);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }
  }

  &.brand-transparent {
    @include theme.apply(background-color, brand-default);
    @include theme.apply(border-color, brand-default);
    @include theme.apply(color, brand-default);
    @include mixins.svgColor(brand-default);

    background-color: transparent;

    &:hover {
      @include theme.apply(background-color, brand-darken);
      @include theme.apply(border-color, brand-darken);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }

    &:active {
      @include theme.apply(background-color, brand-darkest);
      @include theme.apply(border-color, brand-darkest);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }

    &:focus {
      @include theme.apply(background-color, brand-default);
      @include theme.apply(border-color, brand-lighten);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }

    &.disabled {
      @include theme.apply(background-color, brand-disabled);
      @include theme.apply(border-color, brand-disabled);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);
    }
  }

  &.on-brand {
    @include theme.apply(background-color, brand-on-brand);
    @include theme.apply(border-color, brand-on-brand);
    @include theme.apply(color, brand-default);
    @include mixins.svgColor(brand-default);

    &:hover {
      @include theme.apply(background-color, brand-lightest);
      @include theme.apply(border-color, brand-lightest);
      @include theme.apply(color, brand-default);
      @include mixins.svgColor(brand-default);
    }

    &:active {
      @include theme.apply(background-color, brand-darkest);
      @include theme.apply(border-color, brand-darkest);
      @include theme.apply(color, brand-default);
      @include mixins.svgColor(brand-default);
    }

    &:focus {
      @include theme.apply(background-color, brand-on-brand);
      @include theme.apply(border-color, brand-lighten);
      @include theme.apply(color, brand-default);
      @include mixins.svgColor(brand-default);
    }

    &.disabled {
      @include theme.apply(background-color, brand-on-brand-disabled);
      @include theme.apply(border-color, brand-on-brand-disabled);
      @include theme.apply(color, brand-default);
      @include mixins.svgColor(brand-default);
    }
  }

  &.on-brand-transparent {
    @include theme.apply(border-color, brand-on-brand);
    @include theme.apply(color, brand-on-brand);
    @include mixins.svgColor(brand-on-brand);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, brand-lightest);
      @include theme.apply(color, brand-lightest);
      @include mixins.svgColor(brand-lightest);

      background-color: transparent;
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, brand-darkest);
      @include theme.apply(color, brand-darkest);
      @include mixins.svgColor(brand-darkest);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, brand-on-brand-disabled);
      @include theme.apply(color, brand-on-brand-disabled);
      @include mixins.svgColor(brand-on-brand-disabled);

      background-color: transparent;
    }
  }

  &.on-foreground {
    @include theme.apply(background-color, foreground-on-foreground);
    @include theme.apply(border-color, foreground-on-foreground);
    @include theme.apply(color, foreground-default);
    @include mixins.svgColor(foreground-default);

    &:hover {
      @include theme.apply(background-color, foreground-lightest);
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);
    }

    &:active {
      @include theme.apply(background-color, foreground-subtle);
      @include theme.apply(border-color, foreground-subtle);
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);
    }

    &:focus {
      @include theme.apply(background-color, foreground-on-foreground);
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);
    }

    &.disabled {
      @include theme.apply(background-color, foreground-on-foreground-disabled);
      @include theme.apply(border-color, foreground-on-foreground-disabled);
      @include theme.apply(color, foreground-default);
      @include mixins.svgColor(foreground-default);
    }
  }

  &.on-foreground-transparent {
    @include theme.apply(border-color, foreground-on-foreground);
    @include theme.apply(color, foreground-on-foreground);
    @include mixins.svgColor(foreground-on-foreground);

    background-color: transparent;

    &:hover {
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-lightest);
      @include mixins.svgColor(foreground-lightest);

      background-color: transparent;
    }

    &:active,
    &:focus {
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-lightest);
      @include mixins.svgColor(foreground-lightest);

      background-color: transparent;
    }

    &.disabled {
      @include theme.apply(border-color, foreground-on-foreground-disabled);
      @include theme.apply(color, foreground-on-foreground-disabled);
      @include mixins.svgColor(foreground-on-foreground-disabled);

      background-color: transparent;
    }
  }

  &.noborder {
    border: none;
  }

  &.link {
    width: fit-content;
    height: fit-content;

    @include mixins.cleanButton;
    @include theme.apply(color, action-default);
  }
}

[data-site='waCarBuyingCentre'] {
  .button {
    &.on-brand {
      @include theme.apply(background-color, brand-on-brand);
      @include theme.apply(border-color, brand-on-brand);
      @include theme.apply(color, brand-lightest);
      @include mixins.svgColor(brand-lightest);

      &:hover {
        @include theme.apply(background-color, foreground-darkest);
        @include theme.apply(border-color, foreground-darkest);
        @include theme.apply(color, brand-lightest);
        @include mixins.svgColor(brand-lightest);
      }

      &:active {
        @include theme.apply(background-color, brand-on-brand);
        @include theme.apply(border-color, brand-on-brand);
        @include theme.apply(color, brand-lightest);
        @include mixins.svgColor(brand-lightest);
      }

      &:focus {
        @include theme.apply(background-color, brand-on-brand);
        @include theme.apply(border-color, brand-lighten);
        @include theme.apply(color, brand-lightest);
        @include mixins.svgColor(brand-lightest);
      }

      &.disabled {
        @include theme.apply(background-color, brand-on-brand-disabled);
        @include theme.apply(border-color, brand-on-brand-disabled);
        @include theme.apply(color, brand-default);
        @include mixins.svgColor(brand-default);
      }
    }

    &.on-brand-transparent {
      @include theme.apply(border-color, brand-on-brand);
      @include theme.apply(color, brand-on-brand);
      @include mixins.svgColor(brand-on-brand);

      background-color: transparent;

      &:hover {
        @include theme.apply(border-color, brand-darkest);
        @include theme.apply(color, brand-darkest);
        @include mixins.svgColor(brand-darkest);

        background-color: transparent;
      }

      &:active {
        @include theme.apply(border-color, brand-default);
        @include theme.apply(color, brand-default);
        @include mixins.svgColor(brand-default);

        background-color: transparent;
      }

      &:focus {
        @include theme.apply(border-color, brand-on-brand);
        @include theme.apply(color, brand-on-brand);
        @include mixins.svgColor(brand-on-brand);

        background-color: transparent;
      }

      &.disabled {
        @include theme.apply(border-color, brand-lighten);
        @include theme.apply(color, brand-lighten);
        @include mixins.svgColor(brand-lighten);

        background-color: transparent;
      }
    }
  }
}

// default or action button types use the below styles
[data-site='carplace'] { 
  .button:not(.blue-transparent):not(.action-transparent):not(.action-light):not(
      .action-light-transparent
    ):not(.foreground):not(.foreground-transparent):not(.on-action):not(.on-action-transparent):not(.on-accent-1):not(
      .on-accent-1-transparent
    ):not(.on-accent-2):not(.on-accent-2-transparent):not(.brand-transparent):not(.on-brand):not(
      .on-brand-transparent
    ):not(.on-foreground):not(.on-foreground-transparent) {
    @include theme.apply(color, action-on-action);

    background: var(--colour-brand-default-or-gradient);
    border: none;

    svg {
      @include theme.apply(color, action-on-action);
    }

    &:hover {
      background: linear-gradient(90deg, #004352 0.04%, #007050 99.96%);
    }

    &:active {
      background: linear-gradient(90deg, #002730 0.04%, #00422f 99.96%);
    }

    &:focus {
      background: linear-gradient(90deg, #002730 0.04%, #00422f 99.96%);
    }

    &.disabled {
      background: #d1d3cf;
    }
  }
}
